import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from 'react-router-dom';
import { useStoryState } from "./storyState-hook";
import StoryConfig from "../config/storyConfig.json";
const { trackGainValues, scourceTracks, paidTracks, clickedUnlocks } = StoryConfig;
const BASE_AUDIO_PATH = StoryConfig.cdnEndpoint


let audioCtx = null
//  if audio context exists
const AudioContext = window.AudioContext || window.webkitAudioContext;
if (AudioContext) {
  // set context
  audioCtx = new AudioContext()
} else {
  console.log("Web Audio Not Supported");
}


const AudioManagerContext = createContext();
export const useAudioManager = () => useContext(AudioManagerContext);

export default function AudioManagerProvider({ children }) {
  const { unlockTrack, addNotification, unlockedTracks } = useStoryState();
  const [storyPlayerTrack, setStoryPlayerTrack] = useState(null)
  const [backgroundTrack, setBackgroundTrack] = useState(null)

  const [resetTrack, setResetTrack] = useState(null)
  const location = useLocation();
  const storyFadeConstant = 1
  const resetFadeConstant = 1
  const pauseTimeout = 7000

  // gain and mute
  const [muted, setMuted] = useState(null)
  const [gain, setGain] = useState(localStorage.getItem('gain') || 1)
  const prevGain = useRef(0)

  const [backgroundMuted, setBackgroundMuted] = useState(null)

  // check local storage
  const [backgroundGain, setBackgroundGain] = useState(localStorage.getItem('backgroundGain') || 1)
  const prevBackgroundGain = useRef(0)

  const [duration, setDuration] = useState(0);
  const [elapsed, setElapsed] = useState(0);

  const updateDisplayAnimID = useRef(null)
  const prevStoryTrack = useRef(null)
  const audioElementTracks = useRef([])

  const [focused, setFocused] = useState(null)
  const [isFocusTrack, setIsFocusTrack] = useState(false)
  const [isLoaded, setIsLoaded] = useState([])

  // timeout refs
  const storyPlayerTrackTimeoutId = useRef(null)
  const focusTrackTimeoutId = useRef(null)

  const managerReset = useRef(false)


  useEffect(() => {
    audioCtx.onstatechange = () => {
    };
  }, [])


  useEffect(() => {

      // stop animation
      cancelAnimationFrame(updateDisplayAnimID.current)

      // for each audio element
      audioElementTracks.current.forEach(trackData => {

        // fade out
        if (["focus", "backgroundfocusnl"].includes(trackData.type)) {
          trackData.focusedGainNode.gain.setTargetAtTime(0, audioCtx.currentTime, resetFadeConstant)
          trackData.unfocusedGainNode.gain.setTargetAtTime(0, audioCtx.currentTime, resetFadeConstant)
        }
        else {
          trackData.gainNode.gain.setTargetAtTime(0, audioCtx.currentTime, resetFadeConstant)
        }

        // pause and disconnect
        setTimeout(() => {
          if (["focus", "backgroundfocusnl"].includes(trackData.type)) {
            const { unfocusedAudioElement, focusedAudioElement,
              focusedSource, unfocusedSource,
              unfocusedGainNode, focusedGainNode } = trackData

            unfocusedAudioElement.pause()
            focusedAudioElement.pause()

            unfocusedSource.disconnect()
            focusedSource.disconnect()
            unfocusedGainNode.disconnect()
            focusedGainNode.disconnect()
          }
          else {
            trackData.audio.pause()

            trackData.source.disconnect()
            trackData.gainNode.disconnect()
          }
        }, 7000)
      })

      // reset state and refs
      setStoryPlayerTrack(null)
      setBackgroundTrack(null)
      setFocused(false)
      setIsFocusTrack(false)
      prevStoryTrack.current = null
    
  }, [location.key])


  function trackToggled(trackData) {

    const { trackName, path, type, isPlaying } = trackData

    // unlock and add notification
    const unlockTrackName = trackName.split(" (")[0];
    if (!unlockedTracks.includes(unlockTrackName)
      && (paidTracks.includes(unlockTrackName) || clickedUnlocks.includes(unlockTrackName)) ) {
      unlockTrack(unlockTrackName)
      addNotification(unlockTrackName)
    }

    // render/ hide focus button
    if (["backgroundfocusnl", "focus",].includes(type)) {
      // if a focus track but not scource set focus track
      const timeout = scourceTracks[trackName]
      if (!timeout && ["focus", "backgroundfocusnl"].includes(type)) {
        setIsFocusTrack(true)
      }
    }
    else {
      setIsFocusTrack(false)
    }

    // set background track?
    if (type.startsWith('background')) {
      setBackgroundTrack(trackData)
    }

    // set player track?
    setStoryPlayerTrack(trackData)

    // if a different track is playing when we toggle a new track
    if (prevStoryTrack.current
      && prevStoryTrack.current.type != 'sound'
      && prevStoryTrack.current.path != path) {

      const prevTrack = audioElementTracks.current.find(track => track.path === prevStoryTrack.current.path)

      if (prevTrack) {

        if (["focus", "backgroundfocusnl"].includes(prevTrack?.type)) {
          pauseFocus(prevTrack)
        }
        else {
          pause(prevTrack)

        }
      }
      // reset button on story page
      setResetTrack({ path: prevStoryTrack.current.path, isPlaying: false })
    }

    const track = audioElementTracks.current.find(track => track.path === path)
    prevStoryTrack.current = { trackName, path, type, isPlaying }

    // track was played
    if (isPlaying) {
      switch (type) {
        case "focus":
        case "backgroundfocusnl":
          playFocus(track)
          setDuration(track.focusedAudioElement.duration)
          break
        case "sound":
          track.audio.currentTime = 0
          playSound(track)
          break
        default:
          play(track)
          setDuration(track.audio.duration)
      }
    }
    // track was paused
    else {
      switch (type) {
        case "focus":
        case "backgroundfocusnl":
          pauseFocus(track)
          break
        default:
          pause(track)
      }

      if (backgroundTrack) {
        // click the button
        const { path, type } = backgroundTrack
        const domContainer = document.querySelector(`[data-path="${path}"][data-type=${type}]`)
        if (domContainer) {
          prevStoryTrack.current = { ...backgroundTrack }
          domContainer.querySelector('i').click()
        }
      }
    }
  }

  function remapGain(volume, max) {
    return (volume - 0) * (max - 0) / (1 - 0)
  }

  // animation
  useEffect(() => {
    if (!["sound"].includes(storyPlayerTrack?.type)
      && storyPlayerTrack?.isPlaying) {
      cancelAnimationFrame(updateDisplayAnimID.current)
      updateDisplayAnimID.current = requestAnimationFrame(updateDisplay);
    } else {
      // cancelAnimationFrame(updateDisplayAnimID.current)
    }
  }, [storyPlayerTrack]);


  //////////////////////////////////////// Gain & Mute /////////////////////////////////////////////////////

  useEffect(() => {

    if (muted && gain > 0) {

      prevGain.current = gain;
      setGain(0);
    }

    else if (!muted && parseFloat(prevGain.current) > 0) {

      setGain(parseFloat(prevGain.current))
    }
    else if (muted && gain <= 0) {
      prevGain.current = 0
      setMuted(false)
      setGain(1)
    }

  }, [muted])


  useEffect(() => {

    if (backgroundMuted && backgroundGain > 0) {

      prevBackgroundGain.current = backgroundGain;
      setBackgroundGain(0);
    }

    else if (!backgroundMuted && parseFloat(prevBackgroundGain.current) > 0) {
      setBackgroundGain(parseFloat(prevBackgroundGain.current))
    }
    else if (backgroundMuted && backgroundGain <= 0) {
      prevBackgroundGain.current = 0
      setBackgroundMuted(false)
      setBackgroundGain(1)
    }

  }, [backgroundMuted])








  useEffect(() => {

    if (storyPlayerTrack) {

      const track = audioElementTracks.current.find(track => track.path === storyPlayerTrack.path)
      const type = storyPlayerTrack.type
      switch (type) {
        case "background":
        case "backgroundnl":
          track.gainNode.gain.value = remapGain(gain * backgroundGain, trackGainValues[type])
          break
        case "focus":
          if (focused) {
            track.focusedGainNode.gain.value = gain
            return
          }
          track.unfocusedGainNode.gain.value = backgroundGain * remapGain(gain, trackGainValues[type])
          break
        case "backgroundfocusnl":
          if (focused) {
            track.focusedGainNode.gain.value = gain
            return
          }
          track.unfocusedGainNode.gain.value = backgroundGain * remapGain(gain, trackGainValues[type])
          break
        case "transition":
          track.gainNode.gain.value = remapGain(gain, trackGainValues[type])
          break
        case "sound":
          track.gainNode.gain.value = remapGain(gain, trackGainValues[type])
          break
        case "music":
        case "musicalt":
        case "egg":
        case "flashforward":
          track.gainNode.gain.value = gain
          break
        default:
          break
      }
    }

    localStorage.setItem('backgroundGain', backgroundGain)
    localStorage.setItem('gain', gain)


  }, [gain, backgroundGain])


  //////////////////////////////////////// Focus /////////////////////////////////////////////////////

  useEffect(() => {

    if (storyPlayerTrack) {

      const track = audioElementTracks.current.find(track => track.path === storyPlayerTrack.path)

      // let gainOffset = 1
      // if (['backgroundfocusnl'].includes(storyPlayerTrack.type)) {
      //   gainOffset = backgroundGain
      // }


      if (focused) {
        // fade out unfocused
        track.unfocusedGainNode.gain.setTargetAtTime(0, audioCtx.currentTime, storyFadeConstant)
        // fade in focused
        track.focusedGainNode.gain.setTargetAtTime(gain, audioCtx.currentTime, storyFadeConstant)
      }
      else {

        // fade out focused
        track.focusedGainNode.gain.setTargetAtTime(0, audioCtx.currentTime, storyFadeConstant)
        // fade in unfocused
        track.unfocusedGainNode.gain.setTargetAtTime(backgroundGain * remapGain(gain, trackGainValues[storyPlayerTrack.type]), audioCtx.currentTime, storyFadeConstant)
      }
    }
  }, [focused])

  //////////////////////////////////////// Audio Manager Functions /////////////////////////////////////////


  // get tracks from scene
  async function setSceneTracks(tracks) {

    // clear previous tracks
    audioElementTracks.current = []
    managerReset.current = false

    for (const audioData of tracks) {

      const { path, type, trackName } = audioData
      switch (audioData.type) {
        case "music":
        case "flashforward":
        case "egg":
        case "musicalt":
        case "transition":
        case "background":
        case "backgroundnl":
        case "sound":
          const audioElementTrack = await setupAudioTrack(BASE_AUDIO_PATH + path)
          audioElementTrack.audio.onloadedmetadata = () => setIsLoaded(prevState => [...prevState, path])
          audioElementTracks.current.push({ path, type, trackName, ...audioElementTrack })
          break
        case "focus":
        case "backgroundfocusnl":
          const focusURL = BASE_AUDIO_PATH + path.replace('/background/', '/focus/')
          const unfocusURL = BASE_AUDIO_PATH + path
          const trackData = await setupFocusTrack(unfocusURL, focusURL)
          trackData.focusedAudioElement.onloadedmetadata = () => setIsLoaded(prevState => [...prevState, path])
          audioElementTracks.current.push({ path, type, trackName, ...trackData })
          break
        default:
          break
      }
    }
  }

  // create audio element
  function createAudioElement(path) {
    const audio = new Audio()
    audio.crossOrigin = "anonymous"
    audio.src = path
    audio.preload = "metadata"
    return audio
  }



  async function setupFocusTrack(unfocusedPath, focusedPath) {

    // create gain nodes
    const focusedGainNode = audioCtx.createGain()
    const unfocusedGainNode = audioCtx.createGain()

    const focusedAudioElement = createAudioElement(focusedPath)
    const unfocusedAudioElement = createAudioElement(unfocusedPath)

    // create source nodes
    const focusedSource = audioCtx.createMediaElementSource(focusedAudioElement)
    const unfocusedSource = audioCtx.createMediaElementSource(unfocusedAudioElement)

    // connect
    focusedSource.connect(focusedGainNode)
    focusedGainNode.connect(audioCtx.destination)
    unfocusedSource.connect(unfocusedGainNode)
    unfocusedGainNode.connect(audioCtx.destination)

    return {
      focusedGainNode, unfocusedGainNode,
      focusedAudioElement, unfocusedAudioElement,
      focusedSource, unfocusedSource
    }
  }

  async function setupAudioTrack(path) {

    // create gain node
    const gainNode = audioCtx.createGain()
    // create audio element

    const audio = createAudioElement(path)
    // create source element 
    const source = audioCtx.createMediaElementSource(audio)
    // connect
    source.connect(gainNode)
    gainNode.connect(audioCtx.destination)

    return { gainNode, source, audio }
  }

  function updateDisplay() {
    const track = audioElementTracks.current.find(track => track.path === storyPlayerTrack.path)

    if (track) {
      if (["focus", "backgroundfocusnl"].includes(track?.type)) {
        setElapsed(track.focusedAudioElement.currentTime)
      }
      else {
        setElapsed(track.audio.currentTime);
      }

      updateDisplayAnimID.current = requestAnimationFrame(updateDisplay);


      // if focus track, run until focus button is displayed
      if (["focus", "backgroundfocusnl"].includes(track.type)) {

        // if a scource track 
        const timeout = scourceTracks[track.trackName]
        if (timeout > 0) {

          // show focus button after timeout
          if (track.focusedAudioElement.currentTime >= timeout) {
            setIsFocusTrack(true)
          }
          else {
            setIsFocusTrack(false)
          }
        }
      }
    }
  }

  function getSeekTime(seekTime) {
    const track = audioElementTracks.current.find(track => track.path === storyPlayerTrack.path)
    if (track) {
      if (["backgroundfocusnl", "focus"].includes(track.type)) {
        const { focusedAudioElement, unfocusedAudioElement } = track;
        focusedAudioElement.currentTime = seekTime
        unfocusedAudioElement.currentTime = seekTime
        return
      }
      const { audio } = track;
      audio.currentTime = seekTime;
    }
  }

  function playSound(track) {
    // resume context if suspended
    if (audioCtx.state === "suspended") {
      audioCtx.resume();
    }
    track.audio.play()
  }

  // play again
  function loopEnded(path) {
    const track = audioElementTracks.current.find(track => track.path === path)
    play(track)
  }


  function resetStoryButton(path) {
    setStoryPlayerTrack(null)
    setResetTrack({ path, isPlaying: false })
  }

  function play(track) {

    const { gainNode, audio, type, path } = track

    // resume audio state
    if (audioCtx.state === "suspended") {
      audioCtx.resume();
    }

    // if track was paused clear pause timeout
    clearTimeout(storyPlayerTrackTimeoutId.current)


    audio.play()

    // zero gain 
    gainNode.gain.value = 0;

    let gainOffset = 1
    if (["background", "backgroundnl"].includes(type)) {
      gainOffset = backgroundGain
    }

    // fade in track
    gainNode.gain.setTargetAtTime(
      gainOffset * remapGain(gain, trackGainValues[type]),
      audioCtx.currentTime,
      storyFadeConstant
    )


    // onended event listeners
    if (["background", "focus"].includes(type)) {
      audio.onended = () => loopEnded(path)
    }
    else {
      audio.onended = () => resetStoryButton(path)
    }
  }

  function pause(track) {

    const { gainNode, audio } = track

    // fade gain node
    gainNode.gain.setTargetAtTime(0, audioCtx.currentTime, storyFadeConstant)

    cancelAnimationFrame(updateDisplayAnimID.current)

    // pause track after fade out
    storyPlayerTrackTimeoutId.current = setTimeout(() => {
      audio.pause()
    }, pauseTimeout)
    track.audio.onended = null
  }

  function playFocus(track, looped = false) {

    // resume audio state
    if (audioCtx.state === "suspended") {
      audioCtx.resume();
    }
    // clear sliding pause setInterval
    clearTimeout(focusTrackTimeoutId.current)

    // get track data
    const { focusedAudioElement, focusedGainNode,
      unfocusedAudioElement, unfocusedGainNode,
      trackName, type, path } = track

    // if scource track restart from this point
    if (scourceTracks[trackName] && looped) {
      // jump to offset
      focusedAudioElement.currentTime = scourceTracks[trackName] + 1
      unfocusedAudioElement.currentTime = scourceTracks[trackName] + 1
    }
    else if (looped) {
      focusedAudioElement.currentTime = 0
      unfocusedAudioElement.currentTime = 0
    }

    // play elements
    focusedAudioElement.play()
    unfocusedAudioElement.play()

    unfocusedGainNode.gain.value = 0
    focusedGainNode.gain.value = 0


    // fade in focused track
    let gainOffset = 1
    if (["backgroundfocusnl"].includes(type)) {
      gainOffset = backgroundGain
    }

    if (focused) {
      focusedGainNode.gain.setTargetAtTime(
        gain,
        audioCtx.currentTime,
        storyFadeConstant
      );
    }
    // fade in unfocused track
    else {
      unfocusedGainNode.gain.setTargetAtTime(
        gainOffset * remapGain(gain, trackGainValues[type]),
        audioCtx.currentTime,
        storyFadeConstant
      );
    }

    // onended event listeners
    if (type === "focus") {
      focusedAudioElement.onended = () => focusEnded(track)
    }
    else {
      focusedAudioElement.onended = () => resetStoryButton(path)
    }
  }

  // play again without fade in
  function focusEnded(track) {
    setFocused(false)
    playFocus(track, true)
  }

  function pauseFocus(track) {

    // fade gain
    track.focusedGainNode.gain.setTargetAtTime(0, audioCtx.currentTime, storyFadeConstant)
    track.unfocusedGainNode.gain.setTargetAtTime(0, audioCtx.currentTime, storyFadeConstant)

    cancelAnimationFrame(updateDisplayAnimID.current)

    // pause track after fade out
    focusTrackTimeoutId.current = setTimeout(() => {
      track.focusedAudioElement.pause()
      track.unfocusedAudioElement.pause()
    }, pauseTimeout)

    // remove focusEnded event listener
    track.focusedAudioElement.onended = null
  }

  return (
    <AudioManagerContext.Provider value={{
      setStoryPlayerTrack,
      storyPlayerTrack,
      setSceneTracks,
      gain,
      setGain,
      muted,
      setMuted,
      setBackgroundGain,
      backgroundGain,
      setBackgroundMuted,
      backgroundMuted,
      getSeekTime,
      duration,
      elapsed,
      resetTrack,
      setFocused,
      focused,
      isFocusTrack,
      isLoaded,
      trackToggled
    }}>
      {children}
    </AudioManagerContext.Provider>
  );
}


