export default function ComingSoon() {
  return (
    <div id="coming-soon">
            <div className="content-wrap">
        <div className="container clearfix">
      <h1>Coming Soon</h1>
      <p>More in development...<br /><br />
      go {" "}
              <a href="https://mailchi.mp/273eb040151a/fatal-fire-coming-soon" target="_blank" rel="noreferrer">
                here
              </a> to get notified when they're ready...</p>
    </div>
    </div>
    </div>
  );
}